.pps__footer {
  display: flex;
  height: fit-content;
  align-items: center;

  justify-content: center;
  width: 100vw;

  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  left: 0;
  margin-top: 200px;
  background: rgb(64, 3, 55);
  background: -moz-linear-gradient(
    61deg,
    rgba(64, 3, 55, 1) 0%,
    rgba(124, 9, 123, 1) 100%
  );
  background: -webkit-linear-gradient(
    61deg,
    rgba(64, 3, 55, 1) 0%,
    rgba(124, 9, 123, 1) 100%
  );
  background: linear-gradient(
    61deg,
    rgba(64, 3, 55, 1) 0%,
    rgba(124, 9, 123, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#400337",endColorstr="#7c097b",GradientType=1);
}
.footer-links-container p {
  color: white;
  text-decoration: underline;
}

.footer-logo {
  filter: grayscale(1);
  display: flex;
  padding: 1rem;
  align-self: center;
  flex: 0.2;
  align-self: flex-start;
}
.pps__footer-container {
  display: flex;
  align-items: center;
  width: 100%;
}
.pps__footer-container a {
  color: white;
  padding-left: 2rem;
  cursor: pointer;
}
.pps__footer-container a:hover {
  color: rgb(11, 0, 11);
}
.pps__footer-container p {
  display: flex;
  align-self: center;
}
.footer-logo img {
  width: 200px;
  padding: 1rem;
}
.footer-links-container {
  display: flex;
  font-family: var(--font-family-);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#");
  align-items: center;
  height: 100%;
  cursor: pointer;
  padding: 0 1rem;
  justify-content: flex-end;
}

.footer-links {
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
}
.footer-links a {
  display: flex;
  text-align: left;
  padding: 1rem;
  border-radius: 2px;
  font-weight: 400;
  width: fit-content;
}

.headerDivider {
  border-left: 1px solid #2a465e;
  border-right: 1px solid #16222c;
  height: 80px;
  position: absolute;
  right: 249px;
  top: 10px;
}
.links-container {
  display: flex;
  justify-content: flex-end;
  padding-right: 2rem;
  width: 100%;
}
.footer-address-container {
  display: flex;
  flex-direction: column;
}
.divider {
  height: 4rem;
  width: 1px;
  background: white;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#");
  display: flex;
  align-items: center;
  align-self: center;
}
@media screen and (max-width: 780px) {
  .footer {
    flex-direction: column;
    width: 100%;
  }
  .footer-logo {
    flex: 0.6;
  }
  .footer-logo img {
    width: 150px;
    padding: 1rem;
  }
  .pps__footer-container {
    display: flex;
    align-items: center;
    width: 60%;
  }
  .footer-links-container a {
    display: flex;
    text-align: left;
    padding-left: 2rem;
    border-radius: 2px;
    font-weight: 400;
    width: fit-content;
  }
  .links-container {
    flex-direction: column;
    flex: 0.8;
  }
  .footer-links {
    flex-direction: column;
    height: 100%;
    font-size: 10px;
  }
  .footer-links-container {
    display: flex;
    padding: 0;
    padding: 0.5rem;
  }
  .divider {
    display: flex;
    height: 1px;
    width: 90%;
    align-self: flex-start;
    background: rgb(149, 101, 149);
  }
  .pps__footer-container {
    width: 100vw;
    background: var(--gradient-footer);
  }
  .footer-links a {
    display: flex;
    padding: 1rem 0rem;
    align-self: center;
  }
  .footer-links {
    display: flex;
    width: 100%;
    align-items: center;
  }
  .links-container a {
    margin: 0;
    padding: 0;
  }
  .footer-links-container {
    width: 100%;
    justify-content: space-between;
  }
  .links-container {
flex: 0.9;
  }
  .footer-logo {
    flex: 0.1;
  }
  .footer-logo img {
    width: 80px;
  }
}
