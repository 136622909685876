.pps__welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  scroll-margin-top: 132px;
  background: transparent;
  width: 100vw;
  height: 100vh;
  font-family: var(--font-family-);
  text-align: center;
  color: white;
  background-color: transparent;
}
#background-image {
  display: flex;
  height: 100%;
  width: 100%;
  background-image: url("../../assets/images/inlandempire2.png");
  background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  position: fixed;
}
.pps__welcome-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  align-items: center;
  font-size: 52px;
  background: rgb(0, 0, 0);
  background: -moz-linear-gradient(
    61deg,
    rgba(0, 0, 0, 0.35956101190476186) 0%,
    rgba(12, 12, 12, 0) 100%
  );
  background: -webkit-linear-gradient(
    61deg,
    rgba(0, 0, 0, 0.35956101190476186) 0%,
    rgba(72, 69, 69, 0.856) 100%
  );
  background: linear-gradient(
    61deg,
    rgba(0, 0, 0, 0.35956101190476186) 0%,
    rgba(45, 40, 40, 0.856) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ffffff",GradientType=1);
  justify-content: center;
}
.pps__welcome-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  padding: 2rem;
  width: 900px;
  margin-top: 4rem;
  font-size: 40px;
}
.pps__welcome__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 4rem;
  width: 900px;
  font-size: 30px;
}

.pps__welcome-button {
  width: 10rem;
  height: 3rem;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: rgba(206, 184, 206, 0.845);

  transition: all 0.5s ease;
  color: #fff;
  border: 3px solid white;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  text-align: center;
  line-height: 1;
  font-size: 17px;
  padding: 10px;
  outline: none;
  border-radius: 4px;
}
button:hover {
  box-shadow: rgba(58, 56, 59, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(58, 49, 61, 0.09) 0px -3px 5px;
  border-color: rgb(204, 141, 223);
  color: rgb(0, 0, 0);
}

@media screen and (max-width: 1054px) {
  .pps__welcome-title {
    width: 600px;
    font-size: 25px;
    margin-top: 100px;
  }
  .pps__welcome-text {
    font-size: 16px;
    width: 200px;
  }
  .pps__welcome__text {
    width: 400px;
    font-size: 20px;
  }
  .pps__welcome-title {
    font-size: 35px;
    padding-top: 2rem;
    width: 700px;
  }
  .gradient__text3 {
    font-size: 50px;
  }
}
.pps__welcome__button-container {
  margin-bottom: 2rem;
}

@media screen and (max-width: 780px) {
  .pps__welcome-container {
    justify-content: center;
  }
  .pps__welcome-title {
    font-size: 22px;
    padding-top: 2rem;
    width: 200px;
    padding-top: 2rem;
    margin-top: 8rem;
  }
  #background-image {
    background-size: cover;
    height: 100vh;
    margin: 0;
    justify-content: "";
  }

  .pps__welcome-title {
    font-size: 22px;
    padding-top: 2rem;
  }
  .pps__welcome__text {
    font-size: 16px;
    align-self: center;
    align-items: center;
    justify-content: center;
    width: 300px;
  }
  .pps__welcome__button-container {
    margin-bottom: 4rem;
    margin-top: 4rem;
  }
  .pps__welcome-button {
    font-size: 12px;
    width: 9rem;
  }
}
@media screen and (max-width: 400px) {
  .pps__welcome-container {
    justify-content: center;
  }
  .pps__welcome-title {
    font-size: 18px;
    padding-top: 2rem;
    width: 200px;
  }
  .pps__welcome__text {
    font-size: 16px;
    align-self: center;
    align-items: center;
    justify-content: center;
    width: 200px;
    margin-bottom: 0;
  }
  .pps__welcome__button-container {
    margin: 0;
  }
  .gradient__text3 {
    font-size: 40px;
  }
  .links-container {
    margin: 0;
    padding: 0;
  }
}
