@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,807;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "react-alice-carousel/lib/alice-carousel.css";
@import url("https://use.typekit.net/kaa7gct.css");

:root {
  --font-family-: "futura-pt", sans-serif;

  --gradient-background : linear-gradient(221deg, rgba(189,174,199,0.8317533343025807) 0%, rgba(175,154,181,0.8317533343025807) 0%, rgba(189,171,194,0.8626522107070751) 6%, rgba(255,255,255,1) 100%);
  background: -moz-linear-gradient(221deg, rgba(189,174,199,0.8317533343025807) 0%, rgba(175,154,181,0.8317533343025807) 0%, rgba(189,171,194,0.8626522107070751) 6%, rgba(255,255,255,1) 100%);
  background: -webkit-linear-gradient(221deg, rgba(189,174,199,0.8317533343025807) 0%, rgba(175,154,181,0.8317533343025807) 0%, rgba(189,171,194,0.8626522107070751) 6%, rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#bdaec7",endColorstr="#ffffff",GradientType=1);

  --gradient: radial-gradient(
    circle,
    rgba(226, 205, 232, 1) 0%,
    rgba(252, 252, 252, 0.8345623230666257) 79%,
    rgba(255, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e2cde8",endColorstr="#ffffff",GradientType=1);
  background: -webkit-radial-gradient(
    circle,
    rgba(226, 205, 232, 1) 0%,
    rgba(252, 252, 252, 0.8345623230666257) 79%,
    rgba(255, 255, 255, 1) 100%
  );
  background: radial-gradient(
    circle,
    rgba(226, 205, 232, 1) 0%,
    rgba(252, 252, 252, 0.8345623230666257) 79%,
    rgba(255, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e2cde8",endColorstr="#ffffff",GradientType=1);
  /* CSS HEX */
  --black: #000000ff;
  --white: #f5f5f5;
  --lilac: #9d75cbff;
  --purple: #a657aeff;
  --augergine: #8c1a6aff;

  --gradient-html: -moz-linear-gradient(
    142deg,
    rgba(190, 144, 222, 0.7727645702576369) 0%,
    rgba(252, 252, 252, 0.6744499635160639) 81%,
    rgba(255, 255, 255, 0.519955581493592) 100%
  );
  background: -webkit-linear-gradient(
    142deg,
    rgba(190, 144, 222, 0.7727645702576369) 0%,
    rgba(252, 252, 252, 0.6744499635160639) 81%,
    rgba(255, 255, 255, 0.519955581493592) 100%
  );
  background: linear-gradient(
    142deg,
    rgba(190, 144, 222, 0.7727645702576369) 0%,
    rgba(252, 252, 252, 0.6744499635160639) 81%,
    rgba(255, 255, 255, 0.519955581493592) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#be90de",endColorstr="#ffffff",GradientType=1);
  
  
  --gradient-navbar: linear-gradient(
    108deg,
    rgba(175, 119, 216, 0.884) 0%,
    rgb(71, 62, 77)
    rgb(159, 79, 170) 100%
  );
  background: -webkit-linear-gradient(
    rgba(175, 119, 216, 0.884) 0%,
    rgb(71, 62, 77)
    rgb(159, 79, 170) 100%
  );
  background: -moz-linear-gradient(
    rgba(175, 119, 216, 0.884) 0%,
    rgb(71, 62, 77)
    rgb(159, 79, 170) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#be90de",endColorstr="#857e7e",GradientType=1);

  --gradient-footer:linear-gradient(
    108deg,
    rgba(175, 119, 216, 0.884) 0%,
    rgb(71, 62, 77)
    rgb(159, 79, 170) 100%
  );
  background: -webkit-linear-gradient(
    rgba(175, 119, 216, 0.884) 0%,
    rgb(71, 62, 77)
    rgb(159, 79, 170) 100%
  );
  background: -moz-linear-gradient(
    rgba(175, 119, 216, 0.884) 0%,
    rgb(71, 62, 77)
    rgb(159, 79, 170) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#be90de",endColorstr="#857e7e",GradientType=1);


  --gradient-card: -moz-linear-gradient(
    108deg,
    rgba(189, 174, 199, 0.637) 0%,
    rgba(176, 146, 146, 0.623) 44%,
    rgba(182, 141, 224, 0.541) 89%
  );
  background: -webkit-linear-gradient(
    108deg,
    rgba(189, 174, 199, 0.637) 0%,
    rgba(176, 146, 146, 0.623) 44%,
    rgba(182, 141, 224, 0.541) 89%
  );
  background: linear-gradient(
    108deg,
    rgba(189, 174, 199, 0.637) 0%,
    rgba(176, 146, 146, 0.623) 44%,
    rgba(182, 141, 224, 0.541) 89%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#bdaec7",endColorstr="#b78de0",GradientType=1);
}
