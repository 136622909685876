.pps__cards {
  height: fit-content;
  width: 100vw;
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-);
  scroll-margin-top: 20px;
  scroll-snap-margin-top: 20px;
  
}
.pps__cards-heading-container{
  display: flex;
  width: 100vw;
  height: auto;
  justify-content: center;
}
.pps__cards-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
}
.pps__cards-heading {
  display: flex;
  align-self: center;
  color: var(--purple);
  padding: 4rem;
  font-size: 60px;
  text-align: center;
}
.pps__cards-section {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0rem 6rem;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

}
@media screen and (max-width: 1054px) {
  .pps__cards {
    height: auto;
    margin: 0 auto;
    scroll-margin-top: 80px;
    
  }
  .pps__cards-section {
    padding: 0;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
  .desktop-card{
    display: none;
  }
  .pps__cards-heading {
    font-size: 40px;
  }
  .card-description {
    font-size: 9px;
    line-height: 12px;
    height: fit-content;
    text-align: center;
    margin: 0;
  }

}

@media screen and (max-width: 768px) {
  .pps__cards {
    flex-direction: column;
    height: auto;
    margin: 0 auto;
    scroll-margin-top: 80px;
  }
  .pps__cards-section {
    flex-direction: column;
    padding: 0;
    align-items: center;
  }
  .desktop-card{
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .pps__cards {
    flex-direction: column;
    height: auto;
    margin: 0 auto;
    scroll-margin-top: 80px;
  }
  .pps__cards-section {
    flex-direction: column;
    padding: 0;
    align-items: center;
  }
  .desktop-card{
    display: none;
  }
}
@media screen and (max-width: 420px) {
  .pps__cards-section {
    flex-direction: column;
    padding: 0;
    align-items: center;
    width: 100vw;
    flex-wrap: nowrap;
  }
}
