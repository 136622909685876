* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
body {
  text-decoration: none;

  position: relative;
}
html, body {
  overflow-x: hidden;
  
}
body {
  position: relative;
}
.pps__cta-container-app{
  height: fit-content;
  width: 100vw;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 5rem;
}
.gradient__bg {
  background: rgb(247, 247, 255);
  background: var(--gradient-bar);
}
.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gradient__text2 {
  background: linear-gradient(61deg, rgba(18,4,33,1) 0%, rgba(91,75,108,1) 13%, rgba(40,19,61,1) 38%, rgba(74,64,83,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#120421",endColorstr="#4a4053",GradientType=1);
  
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.gradient__background{
  background: rgb(226,205,232);
  background: -moz-radial-gradient(circle, rgba(226,205,232,1) 0%, rgba(252,252,252,0.8345623230666257) 79%, rgba(255,255,255,1) 100%);
  background: -webkit-radial-gradient(circle, rgba(226,205,232,1) 0%, rgba(252,252,252,0.8345623230666257) 79%, rgba(255,255,255,1) 100%);
  background: radial-gradient(circle, rgba(226,205,232,1) 0%, rgba(252,252,252,0.8345623230666257) 79%, rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e2cde8",endColorstr="#ffffff",GradientType=1);  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
}
.section__padding {
  padding: 4rem 6rem;
}
.section__margin {
  margin: 4rem 6rem;
}
.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.gradient__text3{
  background: rgb(226,205,232);
  background: -moz-radial-gradient(circle, rgba(226,205,232,1) 0%, rgba(252,252,252,0.8345623230666257) 79%, rgba(255,255,255,1) 100%);
  background: -webkit-radial-gradient(circle, rgba(226,205,232,1) 0%, rgba(252,252,252,0.8345623230666257) 79%, rgba(255,255,255,1) 100%);
  background: radial-gradient(circle, rgba(226,205,232,1) 0%, rgba(252,252,252,0.8345623230666257) 79%, rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e2cde8",endColorstr="#ffffff",GradientType=1);  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
    -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-5-5 16:33:58
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }
  .section__margin {
    margin: 4rem;
  }
}
@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }
  .section__margin {
    margin: 4rem 2rem;
  }
}
a:target {
  display: block;
  position: relative;
  top: -120px; 
  visibility: hidden;
}
.engrave{
  text-shadow: rgba(245,245,245,0.4) 3px 5px 6px;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-6-9 12:0:56
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
.slide-in-right {
	-webkit-animation: slide-in-right 0.4s cubic-bezier(0.650, 0.085, 0.680, 0.530) both;
	        animation: slide-in-right 0.4s cubic-bezier(0.650, 0.085, 0.680, 0.530) both;
}

 @-webkit-keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
}
.slide-out-right {
	-webkit-animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}