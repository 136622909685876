.pps__cta {
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family-);
  height: 30%;
  padding: 2rem;
  border-radius: 30px;
  background: rgb(189,174,199);
  background: -moz-linear-gradient(221deg, rgba(189,174,199,0.2840005253138167) 0%, rgba(215,12,112,0.7390567050890976) 0%, rgba(231,169,206,0.9328769298081987) 0%, rgba(245,226,190,0.9104050196958392) 50%, rgba(69,0,130,0.2278207500329178) 100%);
  background: -webkit-linear-gradient(221deg, rgba(189,174,199,0.2840005253138167) 0%, rgba(215,12,112,0.7390567050890976) 0%, rgba(231,169,206,0.9328769298081987) 0%, rgba(245,226,190,0.9104050196958392) 50%, rgba(69,0,130,0.2278207500329178) 100%);
  background: linear-gradient(221deg, rgba(189,174,199,0.2840005253138167) 0%, rgba(215,12,112,0.7390567050890976) 0%, rgba(231,169,206,0.9328769298081987) 0%, rgba(245,226,190,0.9104050196958392) 50%, rgba(69,0,130,0.2278207500329178) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#bdaec7",endColorstr="#450082",GradientType=1);
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  color: rgba(128, 0, 128, 0.342);
}
.pps__cta-container{
  display: flex;
}
.pps__cta-title-container{
  display: flex;
  font-size: 30px;
  margin-right: 4rem;
  text-shadow: 0px -1px 0px rgba(141, 136, 136, 0.329);
}
.pps__cta-title {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pps__cta-title h1{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pps__cta-component {
  display: flex;
  margin: 1rem;
  justify-content: flex-end;
  align-items: center;
  color: black;
  margin: 1rem;
}
.pps__cta-component,a{
  text-decoration: none;
  color: black;
}
.pps__cta-component path:hover,path::after {
  color: rgb(248, 217, 248);
  cursor:pointer;
}
.pps__cta-component-container {
  display: flex;
  font-size: 50px;
}
.pps__cta-icon svg {
  display: flex;
  margin: 1rem;
  justify-content: center;
  align-items: center;
  font-size:50px;
  color: rgba(83, 4, 83, 0.534);
}
.pps__cta-component-container{

}
@media screen and (max-width: 768px) {
  .pps__cta {
    padding: 1rem;
    margin: 0;
  }
  .pps__cta-component-container{
    display: flex;
    width:100%;
    margin: 0;
  }
  .pps__cta-component p {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    font-size: 12px;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    order: 2;
  }
  .pps__cta-icon {
    display: flex;
    order: 1;
    margin: 0;
  }
  .pps__cta-icon svg {
    font-size: 40px;
    margin: 0;
  }
}
@media screen and (max-width: 540px) {
  .pps__cta {
  
    align-items: center;
    margin: 0;
  }
  .pps__cta-component-container{
    display: flex;
    width:100%;
    margin: 0;
  }
  .pps__cta-component p {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    font-size: 12px;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    order: 2;
  }
  .pps__cta-icon {
    display: flex;
    order: 1;
    margin: 0;
  }
  .pps__cta-icon svg {
    font-size: 30px;
    margin: 0;
  }
  .pps__cta-title{ 
    font-size: 30px;
  }
}
