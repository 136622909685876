.pps__card {
  width: 500px;
  height: 600px;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: rgb(189, 174, 199);
  background: -moz-linear-gradient(
    108deg,
    rgba(189, 174, 199, 0.4862477163250527) 0%,
    rgba(176, 146, 146, 0.4637758062126931) 44%,
    rgba(183, 141, 224, 0.3963600758756145) 89%
  );
  background: -webkit-linear-gradient(
    108deg,
    rgba(189, 174, 199, 0.4862477163250527) 0%,
    rgba(176, 146, 146, 0.4637758062126931) 44%,
    rgba(183, 141, 224, 0.3963600758756145) 89%
  );
  background: linear-gradient(
    108deg,
    rgba(189, 174, 199, 0.4862477163250527) 0%,
    rgba(176, 146, 146, 0.4637758062126931) 44%,
    rgba(183, 141, 224, 0.3963600758756145) 89%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#bdaec7",endColorstr="#b78de0",GradientType=1);

  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding: 1rem;
  margin: 1rem;
}
.pps__card-container {
  display: flex;
  flex-direction: column;
  height: 600px;
  width: 500px;
  background: var(--gradient-card);
  justify-content: space-around;
  padding: 1rem;
}
.pps__card-description p{ 
  width: 100%;
  padding: 0rem;

}
.pps-card-title {
  font-size: 50px;
  text-align: center;
  display: flex;
  width: 100%;
  justify-content: center;
}

.pps__card-icon {
  display: flex;
  justify-content: center;
}
.pps__card-icon svg {
  font-size: 64px;
  color: #d0a3fa;
  filter: drop-shadow(2px 3px 1px rgba(61, 4, 78, 0.4));
  padding: 1.5rem;
}
f .pps-card-title {
  justify-content: flex-start;
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 1rem;
}
.pps-card-title h1 {
  font-size: 50px;
}
.pps__card::after {
  content: "";
  border-radius: 5px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 15px rgba(185, 112, 219, 0.582);
  opacity: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  border-radius: 40px;
}
.card-description {
  display: flex;
  font-size: 20px;
  padding: 1rem;
  text-align: center;
}
.card-container.card-description p {
  flex-wrap: nowrap;

  align-self: flex-end;
}
.pps__card:hover {
  -webkit-transform: scale(1.25, 1.25);
  transform: scale(1.25, 1.25);
}

.pps__card:hover::after {
  opacity: 1;
}

@media screen and (max-width: 1054px) {
  .pps__card {
    width: 350px;
    height: 500px;
    margin: 2rem;
    justify-content: flex-start;
  }
  .pps__card-container {
    width: 350px;
    height: 500px;
  }
  .card-description {
    font-size: 13px;
    line-height: 16px;
    height: fit-content;
    text-align: center;
    margin: 0;
  }
  .card-description p {
    display: flex;
    justify-content: center;
  }

  .pps-card-title {
    font-size: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 30px;
  }
  .pps-card-title h1 {
    font-size: 30px;
  }
  .pps__card-icon svg {
    font-size: 40px;
  }
}
@media screen and (max-width: 768px) {
  .pps__card {
    width: 200px;
    height: 300px;
    margin-bottom: 1rem;
  }
  .gradient__text2 {
    font-size: 30px;
  }
  .card-description {
    font-size: 9px;
    line-height: 16px;
    height: fit-content;
    text-align: center;
    margin: 0;
  }
  .pps__card-container {
    height: 300px;
    width: 200px;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .pps__card-icon {
    font-size: 40px;
  }
  .pps__card-description {
    width: 100%;
    height: 100%;
    padding: 2rem;
    height: fit-content;
  }
  .card-description p {
    padding-bottom: 2rem;
  }
  .pps__cta-component {
    flex-direction: column;
  }
  .pps__cta-component-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .pps__card-icon svg {
    font-size: 50px;
    display: flex;
    padding-top: 3rem;
  }
  .pps-card-title {
    font-size: 20px;
    text-align: center;
  }
  .pps__card:hover {
    content: "";
    border-radius: 5px;
    position: "absolute";
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: "";
    opacity: "";
    border-radius: "";
    -webkit-transform: "";
    transform: "";
  }
  .pps__card::after {
    -webkit-transition: "";
    transition: "";
  }
  .noHover {
    pointer-events: none;
  }
}
@media screen and (max-width: 2000px) {
  .pps__card {
    width: 400px;
    height: 400px;
    margin-bottom: 1rem;
  }
  .gradient__text2 {
    font-size: 30px;
  }
  .card-description {
    font-size: 13px;
    line-height: 17px;
    text-align: center;
    margin: 0;
    padding: 2rem;
    width: 100%;
  }
  .pps-card-title h1 {
    font-size: 30px;
    text-align: center;
  }
  .pps__card-icon svg {
    font-size: 60px;
  }
}

@media screen and (max-width: 1054px) {
  .card-description p {
    font-size: 9px;
    line-height: 16px;
    text-align: center;
    margin: 0;
  }
  .pps__card {
    width: 300px;
    height: 400px;
    margin-bottom: 1rem;
  }
  .gradient__text2 {
    font-size: 30px;
  }
  .pps__card:hover {
    -webkit-transform: scale(1.20, 1.20);
    transform: scale(1.20, 1.20);
  }
}

@media screen and (max-width: 870px) {
  .pps__card {
    width: 300px;
    height: 400px;
    margin-bottom: 1rem;
  }
  .gradient__text2 {
    font-size: 25px;
  }
  .card-description {
    font-size: 9px;
    line-height: 10px;
    text-align: center;
    margin: 1rem;
    width: 100%;
  }
    .pps-card-title h1 {
      font-size: 20px;
    }
  .pps__card:hover {
    pointer-events: none;
  }
  .pps__card::after {
    pointer-events: none;
  }
  .pps__card-container {
    height: 400px;
    width: 300px;
    flex-wrap: nowrap;
  }
  .pps__card-icon {
    font-size: 40px;
    align-self: center;
    height: 33%;
  }
  .pps__card-description {
    width: 100%;
    padding: 2rem;
  }
  .card-description p {
    font-size: 12px;
    height: 33%;
  }
  .pps__cta-component {
    flex-direction: column;
  }
  .pps__cta-component-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .pps__card-icon svg {
    font-size: 30px;
  }
  .pps-card-title {
    font-size: 20px;
    text-align: center;
  }
  .pps-card-title h1 {
    font-size: 20px;
  }
  .pps__cards-section {
    align-items: flex-end;
  }
}
@media screen and (max-width: 500px) {
  .pps__card {
    width: 300px;
    height: 400px;
    margin-bottom: 1rem;
  }
  .gradient__text2 {
    font-size: 30px;
  }
  .card-description {
    font-size: 9px;
    text-align: center;
    margin: 0;
  }
  .pps__card:hover {
    pointer-events: none;
  }
  .pps__card::after {
    pointer-events: none;
  }
  .pps__card-container {
    height: 400px;
    width: 300px;
    flex-wrap: nowrap;
  }
  .pps__card-icon {
    font-size: 40px;
    align-self: center;
    height: 33%;
  }
  .pps__card-description {
    width: 100%;
    padding: 2rem;
    height: fit-content;
  }
  .card-description p {
    font-size: 12px;
    line-height: 13px;
    height: 33%;
  }
  .pps__cta-component {
    flex-direction: column;
  }
  .pps__cta-component-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .pps__card-icon svg {
    font-size: 30px;
  }
  .pps-card-title {
    font-size: 20px;
    text-align: center;
  }
  .pps-card-title h1 {
    font-size: 20px;
  }
  .pps__cards-section {
    align-items: flex-end;
  }
}
